import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import Word from "../components/RevealingWord"
const Thanks = () => {
  return (
    <Layout>
      <Helmet>
        <meta name="robots" content="noindex"></meta>
        <meta name="googlebot" content="noindex"></meta>
      </Helmet>
      <div className="thankYou">
        <h2 className="pageHeadings mb-48">
          <Word
            word={`Thank you for sharing your project details with us. We will get back to you within 24 hours.`}
          />
        </h2>
        <Link className="primaryCta " to="/">
          Back to Home
        </Link>
      </div>
    </Layout>
  )
}

export default Thanks
